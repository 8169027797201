import React, {useEffect, useState} from "react";
import {Card, Typography, Divider, List} from "antd";
const {Title} = Typography;

const RewardsCard = ({rewardsData}) => {
    return (
        <Card>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Auhinnad</Title>
            </Divider>

            <List
                itemLayout="horizontal"
                dataSource={rewardsData}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<div>{item.icon}</div>}
                            title={<strong>{item.title}</strong>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default RewardsCard;