import {
    FETCH_CHAT_ROOMS_SUCCESS,
    FETCH_CHAT_ROOMS_FAILURE,
    FETCH_CHAT_MESSAGES_SUCCESS,
    FETCH_ACTIVER_ROOM_SUCCESS
} from '../actions/index';

const initialState = {
    data: [],
    loading: true,
    error: null,
    active_room: {id: "post_feed"},
    messages: []
};

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHAT_ROOMS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case FETCH_CHAT_ROOMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: []
            };
        case FETCH_CHAT_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: action.payload,
            };
        case FETCH_ACTIVER_ROOM_SUCCESS:
            return {
                ...state,
                loading: false,
                active_room: action.payload,
            };
        default:
            return state;
    }
}