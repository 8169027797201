import React, { useEffect, useState } from "react";
import {
    Badge,
    Col,
    Divider,
    Progress,
    Row,
    Typography,
    Card,
    Steps,
    message,
    Space,
    Tooltip, notification, Tag
} from "antd";
import api from "../../api";
import {
    CheckCircleOutlined,
    TrophyOutlined,
    LockOutlined,
    TrophyTwoTone,
    StarOutlined,
    StarFilled, StarTwoTone
} from "@ant-design/icons";
import { round } from "lodash";
import BingoCardModal from "./bingo-card-modal";
import { useTranslation } from "react-i18next";
import {useResizeEffect} from "../../resize-effect";

const { Title, Text } = Typography;

const BingoChallenge = () => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [challenge, setChallenge] = useState([]);
    const [level, setLevel] = useState(0);
    const isMobile = useResizeEffect();
    const [notificationApi, contextHolder] = notification.useNotification();
    const openNotification = (value) => {
        notificationApi.info({
            icon: <TrophyTwoTone twoToneColor={"#0EB8AE"}/>,
            className: "unlock-notification",
            message: `Uus tase avatud!`,
            description: <div>Suurepärane! Tegid ära kõik ülesanded ja avasid <strong>Tase {value}!</strong></div>,
            placement: "top",
        });
    };

    useEffect(() => {
        getChallenge();
    }, []);

    useEffect(() => {
        if (challenge) {
            // Find the latest unlocked level
            const lastUnlockedLevel = challenge.findIndex(level => level.completed < level.items.length);

            setLevel(lastUnlockedLevel !== -1 ? lastUnlockedLevel : challenge.length - 1); // Fallback to last level if all are completed
        }
    }, [challenge]);

    const getChallenge = () => {
        api.get('/v2/user/bingo-challenge').then(res => {
            setChallenge(res.data.data);
        });
    };

    const isLevelUnlocked = (index) => {
        if (index === 0) return true; // Level 1 is always unlocked
        return challenge[index - 1]?.completed === challenge[index - 1]?.items?.length;
    };

    const handleStepChange = (newLevel) => {
        if (isLevelUnlocked(newLevel)) {
            setLevel(newLevel);
        }
    };

    const getProgressPercent = () => {
        if (challenge.length === 0 || !challenge[level]) return 0;
        const { completed, items } = challenge[level];
        return items.length > 0 ? round((completed / items.length) * 100) : 0;
    };

    const completeChallengeCard = (item) => {
        api.put(`/v2/user/bingo-challenge/${item.id}`).then(res => {
            const updatedChallenge = res.data.data;
            setChallenge(updatedChallenge);

            // Find the current level and check if all items are completed
            const currentLevelIndex = updatedChallenge.findIndex(level =>
                level.items.some(challengeItem => challengeItem.id === item.id)
            );

            if (currentLevelIndex !== -1) {
                const currentLevel = updatedChallenge[currentLevelIndex];
                const isLevelCompleted = currentLevel.completed === currentLevel.items.length;

                // If current level is completed, check if next level is now unlocked
                if (isLevelCompleted && currentLevelIndex + 1 < updatedChallenge.length) {
                    openNotification(currentLevelIndex + 2);
                } else {
                    if (!item.completed) {
                        message.success(<div>+1 <StarFilled color={"orange"}/> earned!</div>);
                    }
                }
            } else {
                if (!item.completed) {
                    message.success(<div>+1 <StarFilled color={"orange"}/> earned!</div>);
                }
            }

            setModal(false);
        });
    };

    return (
        <Row gutter={[20, 20]} type="flex" justify="center">
            {contextHolder}

            <BingoCardModal setModal={setModal} item={modal} completeChallengeCard={completeChallengeCard} />

            <Col span={24}>
                <Card>
                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Väljakutse tase</Title>
                    </Divider>

                    <Steps
                        responsive={false}
                        current={level}
                        onChange={handleStepChange}
                        items={challenge.map((ch, index) => ({
                            title: !isMobile ? `Tase ${index + 1}` : index + 1,
                            status: index === level ? 'process' : index < level ? 'finish' : 'wait',
                            icon: isLevelUnlocked(index) ? (
                                <TrophyOutlined  />
                            ) : (
                                <Tooltip title="Complete all previous tasks to unlock">
                                    <LockOutlined />
                                </Tooltip>
                            ),
                        }))}
                    />

                    <Divider orientation="left">
                        <Text type="secondary">
                            Earned {challenge[level]?.completed || 0} / {challenge[level]?.items?.length || 0} <StarOutlined/>
                        </Text>
                    </Divider>

                    <Progress
                        percentPosition={{type: 'hidden'}}
                        strokeColor={{ '0%': '#ee7752', '50%': '#e73c7e' }}
                        percent={getProgressPercent()}
                        status="active"
                    />
                </Card>
            </Col>

            <Col span={24}>
                <div className="fitlap-challenge">
                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Ülesanded</Title>
                    </Divider>

                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row type="flex" gutter={[20, 20]}>
                            {challenge[level]?.items?.map(item => (
                                <Col key={item.id} span={24} md={8} lg={6}>
                                        <div onClick={() => setModal(item)}
                                             className={`challenge-card ${item.completed ? "completed" : ""}`}>
                                            <strong>{item.name}</strong>

                                            {!item.completed ? (
                                                <Tag style={{position: "absolute", right: 10, top: 10}}
                                                     className="ant-tag-default">
                                                    <>
                                                        +1 <StarTwoTone twoToneColor={"orange"}/>
                                                    </>
                                                </Tag>

                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                </Col>
                            ))}
                        </Row>
                    </Space>
                </div>
            </Col>
        </Row>
    );
};

export default BingoChallenge;
