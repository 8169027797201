import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {Statistic} from "antd";

const CountdownTimer = ({endDate, isChallengedEnd}) => {
    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        if (!isChallengedEnd) {
            const interval = setInterval(() => {
                const now = moment();
                const duration = moment.duration(endDate.diff(now));
                setTimeLeft(duration);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            // Set timeLeft to 0 when the challenge has ended
            setTimeLeft(moment.duration(0));
        }
    }, [endDate, isChallengedEnd]);

    const days = timeLeft ? Math.floor(timeLeft.asDays()) : 0;
    const hours = timeLeft ? timeLeft.hours() : 0;
    const minutes = timeLeft ? timeLeft.minutes() : 0;
    const seconds = timeLeft ? timeLeft.seconds() : 0;

    return (
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <Statistic title="Päeva" value={days}/>
            <Statistic title="Tundi" value={hours}/>
            <Statistic title="Minutit" value={minutes}/>
            <Statistic title="Sekundit" value={seconds}/>
        </div>
    );
};

export default CountdownTimer;