import React, {useEffect, useState} from "react";
import {
    Layout,
    Row,
    Col,
    Card,
    Typography,
    Progress,
    Button,
    Tag,
    List,
    Avatar,
    Skeleton,
    Space,
    Flex,
    Divider
} from "antd";
import Icon, {ClockCircleOutlined, PlayCircleFilled} from "@ant-design/icons";
import SingleCourseView from "./single-course-view";
import {useNavigate} from "react-router-dom";
import {isMobile, round} from "../helpers";
import {useResizeEffect} from "../resize-effect";
import Api from "../api";
import {useTranslation} from "react-i18next";
import moment from "moment";

const {Content} = Layout;
const {Title, Text} = Typography;

const Courses = ({type}) => {
    const navigate = useNavigate();
    const isMobile = useResizeEffect();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);

        Api.get('/v2/courses').then(res => {
            setLoading(false);


            // Process courses data
            const processedCourses = res.data.map((course, index) => {
                // Determine availability based on the index
                const isAvailable = index < 3 || new Date() >= getNextMonthDate(index - 3);

                return ({
                    ...course,
                    totalTasks: course.tasks.length,
                    completedTasks: course.tasks.filter(task => task.completed).length,
                    isAvailable,
                    availableDate: index < 3 ? null : getNextMonthDate(index - 3),
                })
            });

            setCourses(processedCourses);
        })
    }, []);

    const getNextMonthDate = (offset) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + offset + 1; // Offset from the 4th course onward
        return new Date(year, month, 1); // Constructs the 1st of the given month
    };

    if (type === "vertical" || isMobile) {
        if (loading) {
            return (
                <div>
                    <Skeleton className="skeleton-with-image" avatar paragraph={{ rows: 2 }} title={false} />
                    <Skeleton className="skeleton-with-image" avatar paragraph={{ rows: 2 }} title={false} />
                    <Skeleton className="skeleton-with-image" avatar paragraph={{ rows: 2 }} title={false} />
                    <Skeleton className="skeleton-with-image" avatar paragraph={{ rows: 2 }} title={false} />
                </div>
            )
        }

        return <div>
            {isMobile && (
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>Koolitusvideod</Title>
                </Divider>
            )}

            <List
                style={!isMobile ? {maxHeight: 400, overflow: "scroll", overflowX: "hidden"} : ""}
                itemLayout="horizontal"
                dataSource={courses}
                renderItem={(course) => (
                    <List.Item
                        className={"video-card-list " + (!course.isAvailable ? "unavailable" : "")}
                        onClick={course.isAvailable ? () => navigate(`/courses/${course.id}`) : ""}
                        key={course.id}
                    >
                        <Row type="flex" align="middle" gutter={20}>
                            <Col span={8}>
                                <img style={{width: "100%", borderRadius: 6}} src={course.thumbnail} alt=""/>
                                <Icon className="play-circle-video small" component={PlayCircleFilled}/>
                            </Col>

                            <Col span={16}>
                                {isMobile ? (
                                    <Text style={{fontWeight: 600}}>{course.title}</Text>
                                ) : (
                                    <Title level={5} style={{margin: 0}}>
                                        {course.title}
                                    </Title>
                                )}

                                <Progress
                                    className="eaten-progress"
                                    percent={round((course.completedTasks / course.totalTasks) * 100)}
                                    size="small"
                                />

                                {!course.isAvailable && (
                                    <Tag>Avaneb: {moment(course.availableDate).format('DD.MM.YYYY')}</Tag>
                                )}
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </div>
    }

    return (
        <div>
            <Row type="flex" justify="center">
                <Col span={24} md={24} xxl={20}>
                    <Title level={isMobile ? 4 : 1}>Koolitusvideod</Title>

                    <Row gutter={[16, 16]}>
                        {!loading ? courses.map((course) => {
                            const isCompleted = course.completedTasks === course.totalTasks;

                            return (
                                (
                                    <Col xs={24} md={12} lg={6} key={course.id}>
                                        <Card
                                            className={'video-card-list ' + (!course.isAvailable ? "unavailable" : "")}
                                            onClick={course.isAvailable ? () => navigate(`/courses/${course.id}`) : ""}
                                            hoverable
                                            cover={
                                                <img
                                                    alt={`${course.title} Thumbnail`}
                                                    src={course.thumbnail}
                                                    style={{height: "150px", objectFit: "cover"}}
                                                />
                                            }
                                        >
                                            <Title style={{marginTop: 0}} level={4}>{course.title}</Title>

                                            <Row type="flex" justify="space-between">
                                                <Tag
                                                    style={{visibility: course.completedTasks === 0 ? "hidden" : "visible"}}
                                                    color={isCompleted ? "green" : "yellow"}>
                                                    {isCompleted ? "Tehtud" : "Alustatud"}
                                                </Tag>

                                                <Text className="muted-text">
                                                    <Icon component={ClockCircleOutlined}/> {course.time} min
                                                </Text>
                                            </Row>

                                            <div style={{marginTop: "10px"}}>
                                                <Progress
                                                    className="eaten-progress"
                                                    percent={round((course.completedTasks / course.totalTasks) * 100)}
                                                    size="small"
                                                />
                                                {course.isAvailable && (

                                                <Row type="flex" justify="space-between">
                                                    <Text className="muted-text">
                                                        Ülesanded: {course.completedTasks}/{course.totalTasks}
                                                    </Text>
                                                </Row>
                                                )}
                                            </div>

                                            {course.completedTasks === 0 ? (
                                                <div>
                                                    <Button onClick={course.isAvailable ? () => navigate(`/courses/${course.id}`) : ''}
                                                            size="large"
                                                            type={course.isAvailable ? "primary" : "default"} block
                                                            style={{marginTop: "12px"}}>{course.isAvailable ? "Alusta" : 'Avaneb: ' + moment(course.availableDate).format('DD.MM.YYYY')}</Button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Button size="large"
                                                            type="default" block style={{marginTop: "12px"}}>
                                                        {isCompleted ? "Vaata koolitust" : "Jätka"}
                                                    </Button>
                                                </div>
                                            )}
                                        </Card>
                                    </Col>
                                )
                            )
                        }) : (
                            ([...Array(8).keys()].map(index => (
                                <Col xs={24} md={12} lg={6} key={index}>
                                    <Skeleton className="post-skeleton" title paragraph={{rows: 2}} active/>
                                </Col>
                            )))

                        )}
                    </Row>
                </Col>
            </Row>
        </div>

    );
};

export default Courses;