import {Avatar, Button, Divider, List, message, Row, Tag, Tooltip} from "antd";
import SheetModal from "../blocks/sheet-modal";
import React, {useState} from "react";
import {Space, Typography} from "antd";
import moment from "moment/moment";
import Api from "../../api";
import {getLevelClassname} from "../../helpers/user";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateChatRooms} from "../../actions";
import Icon, {StarFilled, StarOutlined, UserOutlined} from "@ant-design/icons";
import {FitlapGuardIcon} from "../../icons";

const {Title, Paragraph} = Typography;

const UserProfileModal = ({data, visible, setVisible}) => {
    let userData = data.user || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.user.data);

    const startChat = () => {
        setLoading(true);

        if (userData.user_id) {
            Api.post('/v3/chats/direct-message', {user_id: userData.user_id}).then(res => {
                const newChatId = res.data.chat.id;


                // Reload chats after creating new one
                Api.get('/v3/chats').then(res => {
                    dispatch(updateChatRooms(res.data.private_chats))

                    setTimeout(() => {
                        setVisible(false);
                        setLoading(false);

                        navigate(`/messages?open=${newChatId}`);
                    }, 1000)
                })
            })
        } else {
            message.info('missing user id');
        }
    }

    return (
        <SheetModal
            scroll={true}
            footer={false}
            title={""}
            open={visible}
            close={() => setVisible(false)}
        >
            <Space direction="vertical" align="center" style={{width: "100%", marginTop: 40}}>

                <Space direction="vertical" align="center">
                    <Avatar icon={<UserOutlined/>} src={userData.avatar} size={100}/>
                    <Title style={{margin: 0}} level={4} strong>{userData.display_name ? userData.display_name : userData.name} {userData.fitlap_official && (
                        <Tooltip rootClassName="tooltip-in-modal" key={"edit"} title={"Administraator"}>
                            <Icon style={{cursor: "pointer", color: "#19aef0"}} component={StarFilled}/>
                        </Tooltip>
                    )}</Title>
                </Space>

                {userData.level && (
                    <Space direction="vertical" align="center">
                        <Tag
                            className={getLevelClassname(userData.level.current.id)}>Level {userData.level.current.id}</Tag>
                        <Tag>{userData.level.current.title}</Tag>
                    </Space>
                )}

                <br/>
                <Space align="center">
                    <Button type="primary" onClick={() => navigate(`/home-cooks/${userData.slug}`)}>Vaata profiili</Button>

                    {user.user_id !== userData.user_id && user.fitlap_official && (
                        <Button loading={loading} type="default" onClick={startChat}>Saada sõnum</Button>
                    )}
                </Space>
                <br/>
            </Space>
        </SheetModal>
    )
}

export default UserProfileModal;