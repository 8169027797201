import {Col, Divider, Radio, Spin, Typography} from "antd";
import React from "react";
import {withTranslation} from "react-i18next";
import {mastercard, visa} from "../../assets.js";
import {GooglePay} from "../../icons";
import {productPrice} from "../../helpers/products";
import {useSelector} from "react-redux";
import {isMobile, trackEvent} from "../../helpers";
import {useLocation} from "react-router-dom";

const {Title, Text} = Typography;

const PaymentMethods = ({paymentMethods, setPaymentMethod, active, t, product, loading}) => {
    const totalPrice = productPrice(product); // Hide ESTO payment method if price is below 36€
    const location = useLocation();
    const user = useSelector(state => state.user.data);
    const whitelist = ['indroo@live.com', 'irina.vihhoreva@gmail.com', 'kukk.priit@gmail.com', 'renku3@gmail.com', 'irina.tamme@fitlap.ee', 'marri.triin.volt@gmail.com', 'geiug@hotmail.com'];

    return (
        <Col span={24} id="payment-methods-section">
            <Divider orientation="center">
                <Title style={{margin: 0}} level={4}>{t('payments.choose-method')}</Title>
            </Divider>

            <Radio.Group value={active} className="payment-methods radio-buttons">
                {
                    paymentMethods.map((method, index) => {
                        if (method === 'esto' && totalPrice < 36) {
                            return false;
                        }

                        // Hide fortumo payment method
                        if (method === 'fortumo-hosted-dcb') {
                            return false;
                        }

                        return (
                            <Radio.Button disabled={loading} key={index} onClick={() => setPaymentMethod(method)} value={method}>
                                {loading && active === method && <Spin/>} {t('payment_methods.' + method)} {method === 'stripe' && (<><img src={mastercard} alt="mastercard"/> <img src={visa} alt="visa"/></>)}
                            </Radio.Button>
                        )
                    })
                }

                {!isMobile() && location.pathname !== '/fitlap-plus' && (
                    <Radio.Button disabled={loading} style={{minWidth: 100}} key={'google-pay'} onClick={() => {
                        trackEvent('select google pay')
                        setPaymentMethod("stripe")
                    }} value={'stripe'}>
                        <img style={{width: 40, marginBottom: -5}} src={GooglePay} alt="mastercard"/>
                    </Radio.Button>
                )}
            </Radio.Group>
        </Col>
    )
};

export default withTranslation()(PaymentMethods);
