import {Sheet} from "react-modal-sheet";
import {Modal} from "antd";
import {useResizeEffect} from "../../resize-effect";


const SheetModal = ({children, open, className, title, close, footer,scroll, zindex, fullHeight, modalOnly}) => {
    const isMobile = useResizeEffect();

    if (isMobile && !modalOnly) {
        return (
            <Sheet className={"sheet-modal " + (zindex ? 'zindex-500 ' : ' ') + className} detent={fullHeight ? "full-height" : "content-height"} isOpen={open} onClose={close}>
                <Sheet.Container>
                    <Sheet.Header/>

                    {scroll ? (
                        <Sheet.Scroller>
                            {children}
                        </Sheet.Scroller>
                    ) : (
                        <Sheet.Content>
                            {children}
                        </Sheet.Content>
                    )}
                </Sheet.Container>

                <Sheet.Backdrop onTap={close}/>
            </Sheet>
        )
    }

    return (
        <Modal
            destroyOnClose={true}
            className={className}
            title={title}
            open={open}
            onOk={close}
            onCancel={close}
            footer={footer}
        >
            {children}
        </Modal>
    )
}

export default SheetModal;