import React, {useEffect, useState} from "react";
import {Card, Typography, Button, Row, Col, Tag, Divider, Space, Avatar, message} from "antd";
import SheetModal from "../components/blocks/sheet-modal";
import PaymentMethods from "../components/payment/payment-methods";
import {useTranslation} from "react-i18next";
import ChallengeCard from "../components/challenges/challenge-card";
import Api from "../api";

const {Title, Text} = Typography;
const {CheckableTag} = Tag;

const categories = ["Minu väljakutsed", "Tasuta", "Tasulised", "Sammuväljakutse", "Treening", "Tervis"];

const ChallengesPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showPurchase, setShowPurchase] = useState(null);
    const [competitions, setCompetitions] = useState([]);
    const {t} = useTranslation();

    console.log(competitions);

    useEffect(() => {
        Api.get('/v2/competitions').then(response => {
            setCompetitions(response.data);
        })
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    const continuePurchase = () => {
        alert('todo')
    }

    return (
        <div id="challenges">
            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={24} xxl={20}>
                    <div className="text-center">
                        {categories.map((category) => (
                            <CheckableTag
                                className={"recipe-checkable-tag"}
                                key={category}
                                checked={selectedCategory === category}
                                onChange={() => handleCategoryChange(category)}
                            >
                                {category}
                            </CheckableTag>
                        ))}
                    </div>

                    <Divider orientation="left">
                        <Title style={{margin: 0}} level={4}>Väljakutsed</Title>
                    </Divider>

                    <Row gutter={[16, 16]}>
                        {competitions.map((challenge, index) => {
                            return <Col key={index} xs={24} sm={12} md={12} xl={6}>
                                <ChallengeCard setShowPurchase={setShowPurchase} challenge={challenge}/>
                            </Col>
                        })}
                    </Row>

                    <SheetModal footer={false} open={showPurchase} close={() => setShowPurchase(false)}>
                        <div>
                            <Divider orientation="center">
                                <Title style={{margin: 0}} level={4}>Osta väljakutse</Title>
                            </Divider>

                            <Space direction="vertical">
                                <Text>Title: {showPurchase ? <strong>{showPurchase.title}</strong> : ''}</Text>
                                <Text>Price: {showPurchase ? showPurchase.price : ''}$</Text>
                                <Text>Access: {showPurchase ? showPurchase.duration : ''}</Text>
                            </Space>

                            <Row type="flex" justify="center" className="text-center">
                                <PaymentMethods product={{
                                    "product_name": "premium-single-1m",
                                    "price": 5,
                                    "type": "single",
                                    "id": "2-8",
                                    "currency": "EUR",
                                    "period": "1m",
                                    "methods": [
                                        "paypal",
                                        "stripe",
                                        "fortumo-hosted-dcb",
                                        "zlickpay"
                                    ],
                                    "coupon": null,
                                    "coupon_percentage": null,
                                    "discount_price": null
                                }} setPaymentMethod={continuePurchase} paymentMethods={[
                                    "paypal",
                                    "stripe",
                                    "fortumo-hosted-dcb",
                                    "zlickpay"
                                ]}/>
                            </Row>
                        </div>
                    </SheetModal>

                </Col>
            </Row>
        </div>
    );
};

export default ChallengesPage;