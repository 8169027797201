import React, {useEffect, useState} from "react";
import {
    Button,
    Modal,
    Row, Space,
} from "antd";
import {useTranslation} from "react-i18next";
import SheetModal from "../blocks/sheet-modal";

const BingoCardModal = ({item, setModal, completeChallengeCard}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (!item && loading) {
            setLoading(false);
        }
    }, [item])

    return (
        <SheetModal
            className="challenge-modal"
            title={false}
            open={!!item}
            footer={false}
            close={() => setModal(false)}
        >
            <Row type="flex" justify="center" align="middle" className="text-center">
                <h2>{item.name}</h2>
            </Row>

            <Row type="flex" justify="center" align="middle" className="text-center">
                <div dangerouslySetInnerHTML={{__html: item.description}}/>
            </Row>

            <br/>
            <Row type="flex" justify="center" align="middle" className="text-center">
                <Space>
                    <Button type="default" onClick={() => setModal(false)}>{t('common.close')}</Button>
                    <Button onClick={() => {
                        setLoading(true);
                        completeChallengeCard(item);
                    }} disabled={loading}
                            type="primary">{item.completed ? t('mark.uncomplete') : t('mark.completed')}</Button>
                </Space>
            </Row>
            <br/>
        </SheetModal>
    )
}

export default BingoCardModal;
