import {Avatar, Button, Card, Col, message, Progress, Row, Space, Tag, Tooltip, Typography} from "antd";
import {AntDesignOutlined, InfoCircleOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import CountdownTimer from "./countdown-timer";

const {Title, Text} = Typography;

const ChallengeCard = ({challenge, setShowPurchase}) => {
    let hasAccess = !challenge.price;
    const navigate = useNavigate();
    const startDate = challenge.begin_date ? moment(challenge.begin_date) : moment("2025-01-01");
    const endDate = challenge.end_date ? moment(challenge.end_date) : moment("2025-02-28");
    const today = moment(); // Assuming today is the 3rd day of challenge
    const totalDays = endDate.diff(startDate, "days");
    const daysPassed = today.diff(startDate, "days");
    const progressPercent = (daysPassed / totalDays) * 100;

    const joinChallenge = () => {
        navigate('/challenges/' + challenge.id);
    }

    return (
        <Card bordered={false} className="challenge-card-wrapper" cover={challenge.image ?
            <img style={{height: 160, objectFit: "cover"}} src={challenge.image}/> : ''}
              title={''}>

            <Space direction="vertical" style={{width: "100%"}}>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Title style={{ margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} level={5}>{challenge.name}</Title>
                    </Col>
                </Row>

                <Text>{challenge.summary ? challenge.summary : "Osalege meie igakuises sammu väljakutses. Saate väljakutse lõpus preemia võita, tehes kõige rohkem samme"} </Text>

                {progressPercent < 100 && (
                    <Progress strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}
                              percent={parseInt(progressPercent)}
                              percentPosition={{type: 'hidden'}}
                              status={progressPercent < 100 ? "active" : "success"}/>
                )}

                {challenge.participants && (
                    <Row type="flex" justify="space-between">
                        {challenge.price > 0 ?
                            <Tag color="orange">Osalemine: {challenge.price}€</Tag> :
                            <Tag color="cyan">Sammuväljakutse</Tag>}

                        <Avatar.Group className="avatar-group-min" size={"small"} max={{count: 3, popover: {trigger: 'none'}}}>
                            {challenge.participants.map(participant => {
                                return (
                                    <Avatar src={participant.avatar} icon={<UserOutlined/>}/>
                                )
                            })}
                        </Avatar.Group>
                    </Row>
                )}

                <Row type="flex" justify="center" style={{gap: 8}}>
                    {!hasAccess ? (
                        <Button onClick={() => setShowPurchase(challenge)}
                                type="default">Osta ligipääs</Button>
                    ) : (
                        <Button onClick={joinChallenge}
                                type={"primary"}>Ava väljakutse</Button>
                    )}
                </Row>
            </Space>
        </Card>
    )
}

export default ChallengeCard;